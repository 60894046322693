import React, {useEffect} from 'react';
import {  Route, Routes  } from 'react-router-dom';
import Page2 from './Mdas.js';
import MDAs from './Pages/MinistryUrban/MinistryUrban.js';
import Departments from './departments.js';
import Archive from './Pages/Archive/Archive.js';
import MdasMoa from './Pages/MDAs MOA/mdas_moa.js';
import Mdas from './Mdas.js';
import MinistryUrban from './Pages/MinistryUrban/MinistryUrban.js';
import Agencies from './Agencies.js';
import { useLocation } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import Executives from './Pages/Executives/Executives.js';
import Legends from './Pages/Legends/Legends.js';
import LegendDetails from './Pages/LegendDetails/LegendDetails.js';
import ArchiveNew from './Pages/ArchivesNew/ArchiveNew.js';
import Budgets from './Pages/Budgets Documents/Budgets.js';
import Financial from './Pages/Financial Statements/Financial.js';
import ExecutivesDetails from './Pages/Executives Details/ExecutivesDetails.js';
import News from './Pages/News/News.js';
import NewsDetails from './Pages/News/NewsDetails.js';
import Government from './Pages/Government/Government.js';
import Auditor from './Pages/Financial Statements/Auditor.js';
import LocalGovernment from './Pages/Financial Statements/LocalGovernment.js';
import Mtef from './Pages/Financial Statements/MTEF.js';
import PublicProcurement from './Pages/Financial Statements/PublicProcurement.js';
import NewsMdas from './Pages/News Mdas/News.js';
import NewsDetailsMdas from './Pages/News Mdas/NewsDetails.js';
import OgunInvest from './Pages/Ogun Invest/OgunInvest.js';
import Contract from './Pages/Financial Statements/Contract.js';
import ExecutivesDetails2 from './Pages/Executives Details/ExecutivesDetails2.js';
import ExecutivesDetails3 from './Pages/Executives Details/ExecutivesDetails3.js';
import OgunInvestRegistration from './Pages/Ogun Invest/OgunInvestRegistration.js';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import OgunCompleted from './Pages/Ogun Invest/OgunCompleted.js';
import NotFound from './Pages/NotFound/NotFound.js';
import LandingPage from './Pages/Landing/LandingPage.js';


function App() {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);


  return (
    <>

    
    <Routes>
    {/* <Route path='/' element={<NotFound/>}/> */}
    <Route path='/' element={<LandingPage/>}/>
      {/* <Route path='/' element={<OgunInvest/>}/>
      <Route path='/read_news' element={<NewsDetails/>}/>
      <Route path='/news' element={<News/>}/> */}
    </Routes>
 
    <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
}

export default App;
