import React from "react";
import classes from "./LandingPage.module.css";
import image from "./ogunLogo.png";
import image1 from "./ogunInvest.png";
import page from "./WhatsappInvest.jpeg";
import { FaPhoneAlt } from "react-icons/fa";
import { MdOutlineMail } from "react-icons/md";
import { PiMapPin } from "react-icons/pi";
import instatgram from "./instargra.png";
import twitter from "./whiteTwitters.png";
import youtube from "./youtubeWhite.png";
import { Link } from "react-router-dom";

const LandingPage = () => {
  return (
    <div className={classes.top}>
      <div className={classes.navMenu}>
        <div>
          <img src={image} alt="ogunLogo" className={classes.ogunLogoDiv} />
        </div>
        <div>
          <img
            src={image1}
            alt="ogunInvest"
            className={classes.ogunInvestDiv}
          />
        </div>
      </div>
      <div>
        <img src={page} alt="landingPage" className={classes.landingPage} />
      </div>
      <div>
        <caption className={classes.caption}>
          Our new website will be live soon with exciting updates!!!
        </caption>
      </div>
      <div className={classes.footer}>
        <div className={classes.footerDi}>
          <div className={classes.firstFooter}>
            <div className={classes.phoneDiv}>
              <FaPhoneAlt size={20} className={classes.phoneIcon} />
              <div className={classes.number}>
                <p className={classes.phone1}>
                  <Link to={"tel:+2349091661337"} className={classes.phoneLink}>
                    +234 9091661337,
                  </Link>
                </p>
                <p className={classes.phone2}>
                  <Link to={"tel:+2348169213747"} className={classes.phoneLink}>
                    +234 8169213747
                  </Link>
                </p>
              </div>
            </div>
            <div className={classes.emailDiv}>
              <MdOutlineMail size={20} className={classes.phoneIcon} />
              <div>
                <p className={classes.phone1}>
                  <Link
                    tp={"mailto:investment@ogunstate.gov.ng"} 
                    className={classes.emailLink}
                    target="_blank"
                  >
                    investment@ogunstate.gov.ng
                  </Link>
                </p>
              </div>
            </div>

            <div className={classes.emailDiv}>
              <PiMapPin size={20} className={classes.phoneIcon} />
              <div className={classes.loct}>
                <Link
                  to={
                    "https://www.google.com/search?sca_esv=85709b99cef60822&cs=1&output=search&kgmid=%2Fg%2F1pxq8nf1x&q=Opic%20Plaza&shndl=30&source=sh%2Fx%2Fkp%2Flocal%2Fm1%2F1&kgs=3c199b01e21604c8"
                  } target="_blank"
                >
                  <p className={classes.phone1}>
                    4th Floor,Opic Plaza, Isheri, Ogun State,
                  </p>
                </Link>
                <Link to={"https://maps.app.goo.gl/dXduZSSS16jC8kBw9"} target="_blank">
                  <p className={classes.phone1}>
                    Block D, Room 115, State Secretariat, Oke-Mosan, Abeokuta
                  </p>
                </Link>
              </div>
            </div>
          </div>

          <div className={classes.divSocial}>
            <Link
              to={"https://www.instagram.com/investogun/?hl=en"}
              target="_blank"
            >
              <img
                src={instatgram}
                alt="instargram"
                className={classes.social}
              />
            </Link>
            <Link to={"https://www.x.com/InvestOgun"} target="_blank">
              <img src={twitter} alt="twitter" className={classes.social} />
            </Link>

            <Link
              to={"https://youtube.com/@investogun?si=iZYQy8qkKZkw6xZg"}
              target="_blank"
            >
              <img src={youtube} alt="youtube" className={classes.social} />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandingPage;
